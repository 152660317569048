import axiosApi from '@/service/modules/axios-api'
import { SYS_MICRO_SERVICE_PREFIX } from '@/service'
// 查询菜单列表
export function listMenu(params, menuId) {
  return axiosApi.get(SYS_MICRO_SERVICE_PREFIX + '/menu/list', params)
}

// 查询菜单详细
export function getMenu(params) {
  return axiosApi.get(SYS_MICRO_SERVICE_PREFIX + '/menu/getById', params)
}

// 查询菜单下拉树结构
export function treeselect() {
  return axiosApi.get(SYS_MICRO_SERVICE_PREFIX + '/menu/treeselect', {})
}

// 根据角色ID查询菜单下拉树结构
export function roleMenuTreeselect(params) {
  return axiosApi.get(SYS_MICRO_SERVICE_PREFIX + 'menu/roleMenuTreeselect/', params)
}

// 新增菜单
export function addMenu(data) {
  return axiosApi.post(SYS_MICRO_SERVICE_PREFIX + '/menu/add', data)
}

// 修改菜单
export function updateMenu(data) {
  return axiosApi.post(SYS_MICRO_SERVICE_PREFIX + '/menu/edit', data)
}

// 删除菜单
export function delMenu(data) {
  return axiosApi.post(SYS_MICRO_SERVICE_PREFIX + '/menu/remove', data)
}
