<template>
  <div class="app-container">
    <!--查询区域start-->
    <div class="search-area">
      <div class="form-area">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch">
          <el-form-item label="菜单名称" prop="menuName">
            <el-input
              v-model="queryParams.menuName"
              placeholder="请输入菜单名称"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="菜单状态" prop="status">
            <el-select v-model="queryParams.status" placeholder="全部" clearable size="small">
              <el-option
                v-for="(item,index) in menuStatus"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery" :loading="searchBtnLoading">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--查询区域end-->

    <!--结果显示区域start-->
    <div class="table-area">
      <!--操作按钮区域start-->
      <div class="operate-btn">
        <el-button
          type="primary"
          class="add-btn"
          icon="el-icon-plus"
          @click="handleAdd"
          :loading="addBtnLoading"
        >新增</el-button>
        <el-button
          type="info"
          plain
          icon="el-icon-sort"
          size="mini"
          @click="toggleExpandAll"
        >展开/折叠</el-button>
      </div>
      <!--操作按钮区域end-->

      <!--数据表格区域start-->
      <el-table
        v-if="refreshTable"
        v-loading="loading"
        :data="menuList"
        row-key="id"
        :default-expand-all="isExpandAll"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      >
        <el-table-column prop="menuName" label="菜单名称" :show-overflow-tooltip="true" width="160"></el-table-column>
        <el-table-column prop="icon" label="图标" align="center" width="100">
          <template slot-scope="scope">
            <i :class="scope.row.menuIcon"></i>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" width="60"></el-table-column>
        <el-table-column prop="menuPermission" label="权限标识" width="200" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="componentPath" label="组件路径" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="status" label="状态" width="80">
          <template slot-scope="scope">
            <el-tag v-if="Number(scope.row.status)===1" type="success" size="small">正常</el-tag>
            <el-tag v-else type="info" size="small">停用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="createTime">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              :loading="editBtnLoading"
              @click="handleUpdate(scope.row)"
            >修改</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-plus"
              :loading="addBtnLoading"
              @click="handleAdd(scope.row)"
            >新增</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              :loading="deleteBrnLoading"
              @click="handleDelete(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--数据表格区域end-->
    </div>

    <!-- 添加或修改菜单对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="880px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="上级菜单">
              <treeselect
                v-model="form.parentId"
                :options="menuOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="选择上级菜单"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜单类型" prop="menuType">
              <el-radio-group v-model="form.menuType">
                <el-radio label="1">目录</el-radio>
                <el-radio label="2">菜单</el-radio>
                <el-radio label="3">按钮</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.menuType != '3'">
            <el-form-item label="菜单图标" prop="menuIcon">
              <el-input v-model="form.menuIcon" placeholder="请输入图标类名">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单名称" prop="menuName">
              <el-input v-model="form.menuName" placeholder="请输入菜单名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menuType === '2'||form.menuType === '1'">
            <el-form-item prop="menuCode">
              <span slot="label">
                <el-tooltip content="对应ISC中的MenuCode" placement="top">
                <i class="el-icon-question"></i>
                </el-tooltip>
                菜单编码
              </span>
              <el-input v-model="form.menuCode" placeholder="请输入菜单编码" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示排序" prop="sort">
              <el-input-number v-model="form.sort" controls-position="right" :min="0" />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menuType != '3'">
            <el-form-item>
              <span slot="label">
                <el-tooltip content="选择是外链则路由地址需要以`http(s)://`开头" placement="top">
                <i class="el-icon-question"></i>
                </el-tooltip>
                是否外链
              </span>
              <el-radio-group v-model="form.isFrame">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menuType != '3'">
            <el-form-item prop="path">
              <span slot="label">
                <el-tooltip content="访问的路由地址，如：`user`，如外网地址需内链访问则以`http(s)://`开头" placement="top">
                <i class="el-icon-question"></i>
                </el-tooltip>
                路由地址
              </span>
              <el-input v-model="form.path" placeholder="请输入路由地址" />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menuType == '1'||form.menuType == '2'">
            <el-form-item prop="componentPath">
              <span slot="label">
                <el-tooltip content="访问的组件路径，如：`system/user/index`，默认在`views`目录下" placement="top">
                <i class="el-icon-question"></i>
                </el-tooltip>
                组件路径
              </span>
              <el-input v-model="form.componentPath" placeholder="请输入组件路径" />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menuType != '1'">
            <el-form-item>
              <el-input v-model="form.menuPermission" placeholder="请输入权限标识" maxlength="100" />
              <span slot="label">
                <el-tooltip content="控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasPermi('system:user:list')`)" placement="top">
                <i class="el-icon-question"></i>
                </el-tooltip>
                权限字符
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menuType == '2'">
            <el-form-item>
              <el-input v-model="form.query" placeholder="请输入路由参数" maxlength="255" />
              <span slot="label">
                <el-tooltip content='访问路由的默认传递参数，如：`{"id": 1, "name": "ry"}`' placement="top">
                <i class="el-icon-question"></i>
                </el-tooltip>
                路由参数
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menuType == '2'">
            <el-form-item>
              <span slot="label">
                <el-tooltip content="选择是则会被`keep-alive`缓存，需要匹配组件的`name`和地址保持一致" placement="top">
                <i class="el-icon-question"></i>
                </el-tooltip>
                是否缓存
              </span>
              <el-radio-group v-model="form.isCache">
                <el-radio label="1">缓存</el-radio>
                <el-radio label="0">不缓存</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menuType != '3'">
            <el-form-item>
              <span slot="label">
                <el-tooltip content="选择隐藏则路由将不会出现在侧边栏，但仍然可以访问" placement="top">
                <i class="el-icon-question"></i>
                </el-tooltip>
                显示状态
              </span>
              <el-radio-group v-model="form.isVisible">
                <el-radio
                  v-for="(dict,index) in menuStatus"
                  :key="index"
                  :label="dict.value"
                >{{dict.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menuType != '3'">
            <el-form-item>
              <span slot="label">
                <el-tooltip content="选择停用则路由将不会出现在侧边栏，也不能被访问" placement="top">
                <i class="el-icon-question"></i>
                </el-tooltip>
                菜单状态
              </span>
              <el-radio-group v-model="form.status">
                <el-radio
                  v-for="dictItem in menuStatus"
                  :key="dictItem.value"
                  :label="dictItem.value"
                >{{dictItem.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listMenu, getMenu, delMenu, addMenu, updateMenu } from '@/service/api/system/menu.js'
import Treeselect from '@riophae/vue-treeselect'
import { handleTree } from '@/util/normal'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'MenuMgt',
  components: { Treeselect },
  data() {
    return {
      // 按钮加载标识
      searchBtnLoading: false,
      addBtnLoading: false,
      deleteBrnLoading: false,
      editBtnLoading: false,
      // 遮罩层
      loading: false,
      // 显示搜索条件
      showSearch: true,
      // 菜单状态
      menuStatus: [
        {
          label: '正常',
          value: '1'
        },
        {
          label: '停用',
          value: '0'
        }
      ],
      // 菜单表格树数据
      menuList: [],
      // 菜单树选项
      menuOptions: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 是否展开，默认全部折叠
      isExpandAll: false,
      // 重新渲染表格状态
      refreshTable: true,
      // 查询参数
      queryParams: {
        menuName: '',
        status: ''
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        menuName: [
          { required: true, message: '菜单名称不能为空', trigger: 'blur' }
        ],
        menuCode: [
          { required: true, message: '菜单编码不能为空', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '菜单顺序不能为空', trigger: 'blur' }
        ],
        componentPath: [
          { required: true, message: '组件路径不能为空', trigger: 'blur' }
        ],
        path: [
          { required: true, message: '路由地址不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 选择图标
    selected(name) {
      this.form.icon = name
    },
    /** 查询菜单列表 */
    getList() {
      this.searchBtnLoading = this.loading = true
      listMenu(this.queryParams, this.$route.meta.menuId).then(res => {
        if (res.data.status === 200) {
          this.menuList = handleTree(res.data.data, 'id')
        }
        this.searchBtnLoading = this.loading = false
      }).catch(() => {
        this.searchBtnLoading = this.loading = false
      })
    },
    /** 转换菜单数据结构(element表格检测到children存在时就会显示为目录效果,此处需要移除根节点的children字段，确保正常显示) */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children
      }
      return {
        id: node.id,
        label: node.menuName,
        children: node.children
      }
    },
    /** 查询菜单下拉树结构 */
    getTreeselect() {
      listMenu().then(res => {
        this.menuOptions = []
        const menu = { id: '0', menuName: '主类目', children: [] }
        if (res.data.status === 200) {
          menu.children = handleTree(res.data.data, 'id')
        }
        this.menuOptions.push(menu)
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        id: '',
        children: [],
        componentPath: '',
        isCache: '0',
        isFrame: '0',
        isVisible: '1',
        menuCode: '',
        menuIcon: '',
        menuName: '',
        menuPath: '',
        menuPermission: '',
        menuType: '1',
        parentId: '',
        path: '',
        query: '',
        remark: '',
        sort: '',
        status: '1'
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs.queryForm.resetFields()
      this.handleQuery()
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.addBtnLoading = true
      this.reset()
      this.getTreeselect()
      if (row != null && row.id) {
        this.form.parentId = row.id
      } else {
        this.form.parentId = '0'
      }
      this.open = true
      this.title = '添加菜单'
      this.addBtnLoading = false
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 展开/折叠操作 */
    toggleExpandAll() {
      this.refreshTable = false
      this.isExpandAll = !this.isExpandAll
      this.$nextTick(() => {
        this.refreshTable = true
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.editBtnLoading = true
      this.reset()
      this.getTreeselect()
      getMenu({ id: row.id }).then(response => {
        this.form = response.data.data
        this.open = true
        this.title = '修改菜单'
        this.editBtnLoading = false
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      console.log('表单', this.form)
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id && this.form.id !== '') {
            updateMenu(this.form).then(response => {
              if (response.data.status === 200) {
                this.$message('修改成功')
              }
              this.open = false
              this.getList()
            })
          } else {
            addMenu(this.form).then(response => {
              if (response.data.status === 200) {
                this.$message.success('新增成功')
              }
              this.open = false
              this.getList()
            })
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.deleteBrnLoading = true
      this.$confirm('是否确认删除名称为"' + row.menuName + '"的数据项？', '提示').then(function() {
        return delMenu({ id: row.id })
      }).then(res => {
        this.getList()
        if (res.data.status === 200) {
          this.$message.success('删除成功')
        } else if (res.data.status === 100) {
          this.$message.error(res.data.msg)
        } else {
          this.$message.error('删除失败')
        }
        this.deleteBrnLoading = false
      }).catch((err) => {
        console.log(err)
        this.deleteBrnLoading = false
      })
    }
  }
}
</script>
<style scoped lang="scss">
@import "~$assets/css/common/search-area.scss";
@import "~$assets/css/common/table-area.scss";
.app-container{
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}
</style>
